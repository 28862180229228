<template>
  <modal class="set-password-modal" @close="$emit('close')">
    <!-- Titre -->
    <div class="modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>

    <!-- Corps -->
    <div class="modal-body">
      <div class="modal-body-title" :class="modalBodyTitleClass">
        <slot name="icon"><MeepIconExclamationMark class="app-icon"/></slot>
        {{ title }}
      </div>
      <form class="modal-body-content" @submit.prevent="onValidate">
        <p class="modal-body-context" v-html="context"></p>

        <div class="meep-form-column">
          <div class="meep-input">
            <md-field>
              <md-input
                v-model="$v.form.newPassword.$model"
                name="text"
                type="password"
                :placeholder="$t('change-password.new-password')"
                autocomplete="new-password"
              />

              <template v-if="$v.form.newPassword.$dirty">
                <span v-show="!$v.form.newPassword.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
                <span v-show="!$v.form.newPassword.password" class="meep-error">
                  {{ $t("error.password-strength") }}
                </span>
              </template>
            </md-field>
          </div>
          <div class="meep-input">
            <md-field>
              <md-input
                v-model="$v.form.confirmNewPassword.$model"
                name="text"
                type="password"
                :placeholder="$t('change-password.confirm-password')"
                autocomplete="new-password"
              />

              <template v-if="$v.form.confirmNewPassword.$dirty">
                <span
                  v-show="!$v.form.confirmNewPassword.required"
                  class="meep-error"
                >
                  {{ $t("meep-errors.required") }}
                </span>
                <span
                  v-show="
                    !$v.form.confirmNewPassword.sameAsPassword &&
                      $v.form.confirmNewPassword.required
                  "
                  class="meep-error"
                >
                  {{ $t("change-password.verify") }}
                </span>
              </template>
            </md-field>
          </div>
        </div>
      </form>

      <div class="modal-footer">
        <md-button
          type="button"
          class="md-primary--inverted"
          @click.native="$emit('close')"
        >
          Annuler
        </md-button>

        <md-button
          type="submit"
          class="md-raised md-primary"
          @click.native="onValidate"
        >
          Valider
        </md-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconExclamationMark from "@/components/icons/MeepIconExclamationMark.vue";
import { required, sameAs } from "vuelidate/lib/validators";
import usersModel from "../../model/users";
import { password } from "../../services/vuelidate";

export default {
  name: "SetPasswordModal",
  components: {
    MeepIconExclamationMark,
  },

  validations: {
    form: {
      newPassword: {
        required,
        password,
      },

      confirmNewPassword: { required, sameAsPassword: sameAs("newPassword") },
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },

  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC", "isCE"]),

    modalBodyTitleClass() {
      return {
        "modal-body-title-ce": this.isCE,
      };
    },
  },

  methods: {
    async onValidate() {
      const result = !this.$v.$invalid;
      if (result) {
        try {
          await usersModel.setPassword(
            this.$route.params.id,
            this.form.newPassword
          );

          this.$toasted.global.AppSucces({
            message: this.$t("change-password.success-message"),
          });

          this.$emit("close");
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
.set-password-modal {
  .modal-container {
    width: 960px;
    border-radius: 27px;
    padding: 20px 38px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-logo {
      svg {
        width: 105px;
      }
    }
  }

  .modal {
    &-body {
      padding: 0 20px;

      p {
        margin: 5px 0;
      }

      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--modal-title-size-lg);
        font-family: var(--font-bold);
        margin: 10px 0;
        gap: 16px;
        @include for-lg{
          font-size: var(--modal-title-size-xl);
        }
        &-ce {
          font-size: 42px;
        }

        .app-icon {
          width: var(--modal-icon-size-lg);
          height: var(--modal-icon-size-lg);
          @include for-lg{
            width: var(--modal-icon-size-xl);
            height: var(--modal-icon-size-xl);
          }

          &-big {
            width: 75px;
            height: 75px;
          }
        }
      }

      &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .modal-body-context {
          font-size: 22px;
          font-family: var(--font-bold);
          line-height: 22px;
          margin: 20px 0;
          text-align: center;
        }

        .md-field {
          width: 830px;
          margin: 0;
          margin-bottom: 10px;

          &.md-focused,
          &.md-has-value {
            label {
              display: none;
            }
          }
        }

        .label {
          width: 670px;
          margin: 0;
        }
      }
    }
  }

  .modal-footer {
    padding: 20px;
    display: flex;
    justify-content: center;

    .md-primary {
      width: 177.2px;
    }
  }

  .modal-list {
    max-height: 420px;
    overflow-y: scroll;
  }
}

</style>
